<template>
  <div class="userArge">
    <div class="top">
      <img src="@/images/back.png" alt="" @click="backToLogin" />
      <p>{{ $t("login.txt12") }} </p>
    </div>
    <div class="content" v-if="$store.state.index.languageType == 'zh'">
      <p
        >第1.0版本<br />
        生效日期：2021年12月20号。<br /><br
      /></p>
      <p
        >在此特别提醒您（用户）在注册成为用户之前，请认真阅读本用户注册及使用应用程序隐私协议（以下简称“本协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将被视为对本协议的接受，并同意接受本协议各项条款的约束。<br />
        本协议约定上海青甲智能科技有限公司（以下简称本公司）与用户之间关于虚拟织布机NAO（以下简称本应用程序）软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本应用程序服务的个人或相关使用者。本协议可由本应用程序软件系统运行后台及本公司随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本应用程序中查阅最新版协议条款，也提请用户随时关注本应用程序用户协议的更新情况，以免造成不必要的误解和纠纷。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用本应用程序提供的服务，用户继续使用本应用程序提供的服务将被视为接受修改后的协议。
      </p>
      <h5>一、用户账号注册登陆基本要求</h5>
      <p
        >1、用户在使用本服务前需要注册个人账号。该账号应当使用手机号码或者邮箱绑定注册，请用户使用尚未与本账号绑定的手机号码或者邮箱，以及未被本公司根据本协议封禁的手机号码或者邮箱注册账号。本公司可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。<br />
        2、鉴于本应用程序产品的使用特性，用户注册和登陆时应当授权公开及使用其个人信息或者公司信息方可成功注册账号。故用户完成注册和登陆即表明用户同意本应用程序及本公司提取、公开及使用用户的信息（包括商业宣传）。<br />
        3、鉴于本应用程序账号的绑定注册方式，您同意在注册时将允许您的手机号码及手机设备识别码及邮箱等信息用于注册。<br />
        4、在用户注册及登陆使用本应用程序服务时，需要搜集能识别用户身份的个人信息以便本公司可以在必要时联系用户，或为用户提供更好的用户体验。搜集的信息包括但不限于用户的姓名、地址、手机号、邮箱等基本信息，且用户同意对这些信息的使用将受限于第二条用户个人隐私信息保护的约束。</p
      >
      <h5>二、用户个人隐私信息保护</h5>
      <p
        >1、如果本应用程序运行后台及本公司发现或收到他人举报或投诉用户违反本协议约定的，本公司有权在收到举报后24小时内不经通知随时对相关内容，包括但不限于用户资料、上传及发贴记录进行审查、删除，并视情节轻重对违规账号处以包括但不限于警告、账号封禁
        、设备封禁、功能封禁等处罚，且不必通知用户处理结果。<br />
        2、因违反用户协议被封禁的用户，可以自行与本应用程序后台或本公司公开的方式进行联系。其中，被实施功能封禁的用户会在封禁期届满后自动恢复被封禁功能。被封禁用户可提交申诉，本应用程序后台或本公司将对申诉进行审查，并自行合理判断决定是否变更处罚措施。<br />
        3、用户理解并同意，本应用程序后台及本公司有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。<br />
        4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当对本公司与合作公司、关联公司等相关方的赔偿，并使之免受损害。<br />
        5、用户体验改进，我们可能会收集经过MD5算法和加密的国际移动设备身份码（IMEI）和网络设备地址（MAC）,广告标识符IDFA，系统版本号，设备型号，系统编号，系统ID号，操作系统版本号，屏幕分辨率，上网模式，本款产品的版本号信息，某个按钮的点击使用时间及频率，某些关键配置选项值，软件崩溃日志等，用于统计我们的产品数量、分析产品使用情况、版本升级判断、排查崩溃原因、减少崩溃等情况，以此不断改善我们的产品。这些信息不涉及到您个人身份等隐私信息。</p
      >
      <h5>三、用户使用内容规范</h5>
      <p
        >1、本条所述用户使用内容是指用户使用本应用程序的过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字（包括艺术字）、符号、标识、语音、图片、（自定义）照片、视频（包括用户自行制作或上传的短视频等）、图文、服装3D模型、织物结构数据参数及其相互组合的内容等上传、发送、回复或自动回复消息和相关链接页面，以及其他使用本账号或本应用程序服务所产生的内容。<br />
        2、用户不得利用本账号或本应用程序服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：<br />
        (1) 违反宪法所确定的基本原则的； <br />
        (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； <br />
        (3) 损害国家荣誉和利益的； <br />
        (4) 煽动民族仇恨、民族歧视，破坏民族团结的； <br />
        (5) 破坏国家宗教政策，宣扬邪教和封建迷信的； <br />
        (6) 散布谣言，扰乱社会秩序，破坏社会稳定的； <br />
        (7)
        诱导未成年人违法犯罪和散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        <br />(8) 侮辱或者诽谤他人，侵害他人合法权益的； <br />
        (9) 危害社会公德，损害民族优秀文化传统的；
        <br />(10) 非法的广播电视频道、视听节目网站提供的非法视频内容；
        <br />(11) 有关法律、行政法规和国家规定禁止的其他内容。<br />
        3、用户不得利用本账号或本应用程序服务制作、上载、复制、发布、传播如下干扰本应用程序正常运营，以及侵犯其他用户或第三方合法权益的内容：<br />
        (1) 含有任何性或性暗示的； <br />(2) 含有辱骂、恐吓、威胁内容的；<br />
        (3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br />
        (4) 涉及他人隐私、个人信息或资料的；<br />
        (5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br />
        (6)
        含有其他干扰本应用程序服务正常运营和侵犯其他用户或第三方合法权益内容的信息。<br />
        4、用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。<br />
        5、用户须对自己在使用本应用程序服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在本公司首先承担了因其用户行为导致的行政处罚或侵权损害赔偿的法律责任后，用户应给予本公司等额的赔偿，以及本公司因此而受到的其他损失等。</p
      >
      <h5>四、用户使用应用程序规则</h5>
      <p
        >1、用户在使用本应用程序服务中或通过本应用程序所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表本应用程序所属公司（以下简称本公司）的观点、立场或政策，本公司对此不承担任何责任。<br />
        2、用户不得利用本账号或本应用程序服务进行如下行为：<br />
        (1) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；<br />
        (2) 强制、诱导其他用户关注、点击链接页面或分享信息的；<br />
        (3) 虚构事实、隐瞒真相以误导、欺骗他人的；<br />
        (4) 利用技术手段批量建立虚假账号的；<br />
        (5) 利用本账号或本应用程序服务从事任何违法犯罪活动的；<br />
        (6)
        制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br />
        (7)
        其他违反法律法规规定、侵犯其他用户合法权益、干扰本应用程序及其所属公司正常运营或本应用程序未明示授权的行为。<br />
        3、用户须对利用本账号或本应用程序服务传送信息的真实性、合法性、无害性、准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用户自行承担，与本应用程序及本公司无关。如因此给本应用程序及本公司或任何第三方造成损害的，用户应当依法予以赔偿，本公司亦将保留追究相关用户及使用人的法律、经济等全部责任。<br />
        4、本公司提供的本应用程序服务中可能包括广告，用户同意在使用过程中显示本公司和第三方供应商、合作伙伴提供的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，本公司不承担任何责任。
      </p>
      <h5>五、知识产权及其他合法权益保护声明</h5>
      <p
        >1
        、本应用程序上传及使用功能专为用户自己创作或享有合法来源的作品提供服务。本应用程序及本公司尊重他人知识产权和合法权益，请用户在上传相关内容前确保拥有上传内容的著作权及信息网络传播权或者已经取得上述全部权利人的许可。<br />
        2
        、对于用户上传到本应用程序上及使用本应用程序服务过程中，用户同意本应用程序及本公司在全世界范围内具有免费的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和/或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。<br />
        3
        、用户应确保上传的相关内容，已经获得被拍摄人（如有）的许可，并确保作品内容没有侵犯他人的人身权，包括但不限于名誉权、肖像权、隐私权、姓名权，不存在任何著作权纠纷。</p
      >
      <h5>六、服务风险声明</h5>
      <p
        >1、用户完全理解并同意，本应用程序服务涉及到互联网及移动通讯等服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、GSM网络、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。使用本服务的用户须承担以上风险，本公司对服务之及时性、安全性、准确性不作担保，对因此导致用户不能发送和接受消息、或传递错误，个人设定之时效、未予储存或其他问题不承担任何责任。对于不可抗力或非本应用程序及本公司过错原因导致的用户数据损失、丢失或服务停止，本应用程序及本公司将不承担任何责任。<br />
        2、对于系统发生故障影响到本应用程序服务的正常运行，本公司承诺会及时处理并尽可能进行修复。但用户因此而产生的经济和精神损失，本公司不承担任何责任。此外，本应用程序保留不经事先通知为优化、升级或其他目的暂停本应用程序服务任何部分或全部的权利。<br />
        3、本应用程序及本公司郑重提请您注意，任何经由本应用程序服务以上载的相关内容，均由账号所有者承担责任。本公司无法控制经由本应用程序服务上载之内容，也无法对用户的使用行为进行全面控制，因此不保证内容的合法性、正确性、完整性、真实性或品质；您已预知使用本应用程序服务时，可能会接触到令人不快、不适当或令人厌恶之内容，并同意将自行加以判断并承担所有风险，而不依赖于本应用程序及本公司。但在任何情况下，本应用程序及本公司有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本应用程序服务的全部或部分功能，保存有关记录，并向有关机关报告。本应用程序后台及本公司有权(但无义务)依其自行之考量，拒绝和删除可经由本应用程序服务提供之违反本条款的或其他引起本应用程序或其他用户反感的任何内容。<br />
        4、用户完全理解并同意，若第三方在您不知情或未经您同意的前提下，将您的作品上传于本应用程序上及由此所产生的任何可能侵害您权益的行为，本应用程序及本公司均不对任何人承担任何责任。<br />
        5、用户完全理解并同意，第三方可以通过访问本应用程序上的相关信息，并可对信息进行使用行为。对用户或第三方以任何方式进行的使用可能侵害您权益的行为，本应用程序及本公司均不对任何人承担任何责任。<br />
        6、用户完全理解并同意，在发现用户使用应用程序过程中出现的任何有争议的内容或用户本身存在任何争议性，本公司及本应用程序坚决零容忍，并承诺一经发现，严肃处理，包括但不限于删除用户发布的内容、封禁或注销用户个人账户以及向有关部门提供其账户及个人信息等。对此，本公司及本应用程序拥有自主识别相关争议内容和争议用户的决定权，用户一旦使用本应用程序视为无条件接受本条款的约束和规定。</p
      >
      <h5>七、其他提示</h5>
      <p
        >1、本公司郑重提醒用户注意本协议中免除本应用程序及本公司责任和限制用户权利的条款，请用户仔细阅读，自主考虑及辨别相关风险。未成年人应在法定监护人的陪同下阅读本协议及使用本应用程序服务。<br />
        2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和本应用程序及本公司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交本公司住所地有管辖权的人民法院管辖。 本公司有权启动必要的刑事及民事法律程序，维护本公司的合法权益，追究违法用户的法律责任。<br />
        3、本协议的任何条款无论因何种原因无效或不具有可执行性，其余条款仍有效，对双方具有约束力。<br />
        4、本应用程序软件及本公司未行使或执行本服务协议任何权利或规定，不构成对前述权利或其他权利之放弃。<br />
        5、本协议未尽事宜，以本应用程序运行后台及本公司后续不定期更新或补充本协议相关内容为准，更新及补充条款与本协议不可分割。<br />
        6、本协议相关条款的最终解释权归本公司所有。</p
      >
      <p></p>
    </div>
    <div class="content" v-else>
      <p
        >Version 1.0<br />
        Effective Date: February 20, 2024<br /><br
      /></p>
      <p
        >Before registering as a user, you (the user) are hereby reminded to
        carefully read this User Registration and Use of Application Privacy
        Agreement (hereinafter referred to as "this Agreement") to ensure that
        you fully understand the various terms of this Agreement. Please
        carefully read and choose to accept or not accept this Agreement. Unless
        you accept all the terms of this Agreement, you have no right to
        register, log in, or use the services involved in this Agreement. Your
        registration, login, and use will be deemed as acceptance of this
        Agreement and agreement to be bound by the terms of this Agreement.<br />
        This Agreement sets forth the rights and obligations between Shanghai
        Qingjia Intelligent Technology Co., Ltd. (hereinafter referred to as
        "this Company") and users regarding the Virtual Knitting Machine NAO
        (hereinafter referred to as "this Application") software service
        (hereinafter referred to as the "Service"). "User" refers to individuals
        or related users who register, log in, and use the services of this
        application. This Agreement may be updated by the background operation
        of the application software system and this company at any time. Once
        the updated terms of the agreement are announced, they will replace the
        original terms of the agreement without further notice. Users can view
        the latest version of the agreement terms in this application and are
        advised to pay attention to the update of the user agreement of this
        application at any time to avoid unnecessary misunderstandings and
        disputes. After modifying the terms of the agreement, if the user does
        not accept the modified terms, please stop using the services provided
        by this application immediately. Continued use of the services provided
        by this application will be deemed as acceptance of the modified
        agreement.
      </p>
      <h5>**I. Basic Requirements for User Account Registration and Login**</h5>
      <p
        >1. Before using this service, users need to register a personal
        account. The account should be registered with a mobile phone number or
        email. Please use a mobile phone number or email that has not been bound
        to this account, and has not been banned by this company according to
        this agreement. This company may change the account registration and
        binding methods according to user needs or product needs without prior
        notice to users.<br />
        2. Given the usage characteristics of this application product, users
        should authorize the public use of their personal or company information
        to successfully register an account. Therefore, the user agrees that
        completing registration and login indicates that the user agrees to the
        extraction, publicity, and use of the user's information (including
        commercial promotion) by this application and this company.<br />
        3. Given the account binding registration method of this application,
        you agree to allow your mobile phone number, mobile device
        identification code, and email information to be used for
        registration.<br />
        4. When users register and log in to use the services of this
        application, it is necessary to collect personal information that can
        identify the user's identity so that this company can contact the user
        when necessary or provide a better user experience. The collected
        information includes but is not limited to the user's name, address,
        mobile phone number, email address, and other basic information, and the
        user agrees that the use of this information will be subject to the
        constraints of the second item of the protection of personal privacy
        information of users.</p
      >
      <h5>**II. Protection of User Personal Privacy Information**</h5>
      <p
        >1. If this application runs in the background and this company finds or
        receives reports or complaints from others that users violate the
        provisions of this agreement, this company has the right to review and
        delete relevant content, including but not limited to user information,
        upload and post records, within 24 hours after receiving the report,
        without prior notice, and may impose penalties such as warnings, account
        bans, device bans, and function bans on accounts involved in violations,
        without notifying users of the results.<br />
        2. Users who have been banned for violating the user agreement can
        contact the application backend or this company in public. Among them,
        users who have been subject to function bans will automatically restore
        their banned functions after the ban period expires. Banned users can
        submit appeals, and the application backend or this company will review
        the appeals and make reasonable judgments to decide whether to change
        the penalty measures.<br />
        3. Users understand and agree that the application backend and this
        company have the right to punish behaviors that violate relevant laws
        and regulations or the provisions of this agreement according to
        reasonable judgments, take appropriate legal actions against illegal
        users, and report relevant information to relevant departments in
        accordance with laws and regulations, and users shall bear all legal
        liabilities arising therefrom.<br />
        4. Users understand and agree that any claims, demands, or losses
        claimed by third parties due to the user's violation of this agreement,
        including reasonable attorney fees, shall be compensated by the user to
        this company, cooperative companies, affiliated companies, and other
        relevant parties, and shall not harm them.<br />
        5. For user experience improvement, we may collect internationally
        mobile device identity codes (IMEIs) and network device addresses
        (MACs), advertising identifiers (IDFAs), system version numbers, device
        models, system numbers, system ID numbers, operating system version
        numbers, screen resolutions, internet modes, version number information
        of this product, click usage time and frequency of certain buttons, some
        key configuration option values, software crash logs, etc., using MD5
        algorithm and encryption, for statistical purposes such as product
        quantity, analysis of product usage, version upgrade judgment,
        troubleshooting crash reasons, reducing crashes, etc., to continuously
        improve our products. These information does not involve your personal
        identity or other privacy information.</p
      >
      <h5>**III. User Content Usage Specification**</h5>
      <p
        >1. User content referred to in this article refers to any content made,
        uploaded, copied, published, or disseminated by users during the use of
        this application, including but not limited to account avatars, names,
        user descriptions, registration information, authentication materials,
        or texts (including artistic fonts), symbols, logos, voices, pictures,
        (custom) photos, videos (including short videos made or uploaded by
        users), graphics, clothing 3D models, fabric structure data parameters
        and their combinations, etc., uploaded, sent, replied to, or
        automatically replied to messages and related link pages, as well as
        other content generated by using this account or the services of this
        application.<br />
        2. Users shall not use this account or the services of this application
        to create, upload, copy, publish, or disseminate the following contents
        prohibited by laws, regulations, and policies:<br />
        (1) Violating the basic principles of the Constitution;<br />
        (2) Endangering national security, leaking state secrets, subverting
        state power, or undermining national unity;<br />
        (3) Harming national honor and interests;<br />
        (4) Inciting ethnic hatred or discrimination, undermining ethnic
        unity;<br />
        (5) Undermining the state's religious policies, promoting cults and
        feudal superstitions;<br />
        (6) Spreading rumors, disrupting social order, undermining social
        stability;<br />
        (7) Inducing minors to commit illegal crimes and disseminating obscene,
        pornographic, gambling, violent, murderous, terrorist, or inciting
        criminal acts;<br />
        (8) Insulting or defaming others, infringing on the legitimate rights
        and interests of others;<br />
        (9) Harming social morality, damaging the excellent cultural traditions
        of the nation;<br />
        (10) Illegal video content provided by illegal TV channels or
        audiovisual websites;<br />
        (11) Other content prohibited by laws, administrative regulations, and
        national regulations.<br />
        3. Users shall not use this account or the services of this application
        to create, upload, copy, publish, or disseminate content that interferes
        with the normal operation of this application and infringes on the
        legitimate rights and interests of other users or third parties,
        including but not limited to:<br />
        (1) Containing any sexual or suggestive content;<br />
        (2) Containing abusive, intimidating, threatening content;<br />
        (3) Containing harassment, spam advertising, malicious information,
        deceptive information;<br />
        (4) Involving others' privacy, personal information, or data;<br />
        (5) Infringing on others' right of reputation, right of portrait,
        intellectual property rights, trade secrets, and other legal rights;<br />
        (6) Containing other information that interferes with the normal
        operation of this application service and infringes on the legitimate
        rights and interests of other users or third parties.<br />
        4. Users shall not copy, reproduce, sell, resell, or use any part of
        this service or the use or access to this service for any other
        commercial purposes.<br />
        5. Users shall be responsible for their actions in using this
        application service. The forms of legal liability that users shall bear
        include but are not limited to: compensating the injured party, and
        after this company has first assumed the administrative penalties or
        infringement compensation liability caused by the user's actions, the
        user shall compensate this company for an equivalent amount, and bear
        any other losses suffered by this company as a result.</p
      >
      <h5>**IV. Application Rules for User Use**</h5>
      <p
        >1. The content submitted, published, transmitted, or posted by users
        during the use of this application service does not reflect or
        represent, nor should it be deemed to reflect or represent the views,
        positions, or policies of the company to which this application belongs
        (hereinafter referred to as "this Company"), and this company assumes no
        responsibility for this.<br />
        2. Users shall not engage in the following behaviors using this account
        or the services of this application:<br />
        (1) Submitting false information or stealing others' avatars or
        information, impersonating or using others' identities;<br />
        (2) Forcing or inducing other users to follow, click on link pages, or
        share information;<br />
        (3) Fabricating facts, concealing the truth to mislead or deceive
        others;<br />
        (4) Using technical means to establish fake accounts in bulk;<br />
        (5) Engaging in any illegal activities using this account or the
        services of this application;<br />
        (6) Creating, publishing, or operating methods and tools related to the
        above behaviors, or operating or disseminating such methods or tools,
        regardless of whether these behaviors are for commercial purposes;<br />
        (7) Other behaviors that violate laws and regulations, infringe on the
        legitimate rights and interests of other users, interfere with the
        normal operation of this application and its affiliated companies, or
        are not expressly authorized by this application.<br />
        3. Users shall be fully responsible for the authenticity, legality,
        harmlessness, accuracy, and effectiveness of the information transmitted
        using this account or the services of this application, and any legal
        liability related to the information transmitted by the user shall be
        borne by the user, and shall not be related to this application or this
        company. If any damage is caused to this application, this company, or
        any third party due to this, the user shall compensate in accordance
        with the law, and this company will reserve the right to pursue all
        legal, economic, and other responsibilities of relevant users and
        users.<br />
        4. This company provides advertisements in the services of this
        application, and users agree to display advertisements provided by this
        company and third-party suppliers, partners, and partners during use.
        Except as expressly provided by laws and regulations, users shall be
        responsible for transactions made based on such advertisement
        information. This company shall not be liable for any losses or damages
        suffered by users or third parties due to transactions based on such
        advertisement information.
      </p>
      <h5>
        **V. Declaration of Protection of Intellectual Property Rights and Other
        Legal Rights**
      </h5>
      <p
        >1. The upload and use functions of this application are specifically
        designed to provide services for users' own creations or legally sourced
        works. This application and this company respect the intellectual
        property rights and legitimate rights and interests of others. Users
        should ensure that they have the copyright and information network
        dissemination rights of the uploaded content or have obtained permission
        from all the above-mentioned rights holders before uploading relevant
        content.<br />
        2. For users who upload and use content on this application and use the
        services of this application, users agree that this application and this
        company have the right and license to use, copy, modify, adapt, publish,
        translate, create derivative works, disseminate, perform, and display
        such content (in whole or in part), and/or incorporate such content into
        any other known or developed form of works, media, or technology.<br />
        3. Users shall ensure that the relevant content uploaded has obtained
        the permission of the photographed person (if any) and ensure that the
        content of the work does not infringe on the personal rights of others,
        including but not limited to the right of reputation, the right of
        portrait, the right of privacy, the right of name, and there are no
        disputes over copyright.</p
      >
      <h5>**VI. Service Risk Statement**</h5>
      <p
        >1. Users fully understand and agree that the services of this
        application involve internet and mobile communication services, which
        may be affected by various unstable factors in various links. Therefore,
        there are risks of service interruption or failure to meet user
        requirements due to force majeure, computer viruses or hacker attacks,
        system instability, user location, user shutdown, GSM network, Internet
        network, communication line reasons, etc. Users using this service shall
        bear the above risks, and this company does not guarantee the
        timeliness, security, and accuracy of the service, nor does it assume
        any responsibility for the user's inability to send and receive
        messages, or transmission errors, expiration of personal settings,
        non-storage, or other issues. For losses or damages caused by force
        majeure or non-fault reasons of this application and this company
        leading to user data loss, loss, or service suspension, this application
        and this company will not bear any responsibility.<br />
        2. In the event of a system failure affecting the normal operation of
        the services of this application, this company promises to handle it
        promptly and repair it as much as possible. However, this company shall
        not be liable for any economic or mental losses incurred by users as a
        result. In addition, this application reserves the right to suspend any
        part or all of the services of this application for optimization,
        upgrades, or other purposes without prior notice.<br />
        3. This application and this company solemnly remind you that any
        related content uploaded through the services of this application is the
        responsibility of the account owner. This company cannot control the
        content uploaded through the services of this application or
        comprehensively control the user's usage behavior, so it does not
        guarantee the legality, correctness, completeness, truthfulness, or
        quality of the content; you have anticipated that when using this
        application service, you may encounter unpleasant, inappropriate, or
        disgusting content, and agree to judge and bear all risks on your own,
        without relying on this application and this company. However, in any
        case, this application and this company have the right to stop
        transmitting any of the above-mentioned content and take corresponding
        actions, including but not limited to suspending the user's use of all
        or part of the functions of this application, keeping relevant records,
        and reporting to relevant authorities. This application's backstage and
        this company have the right (but not the obligation) to refuse and
        delete any content that violates the provisions of this clause or other
        content that causes discomfort to this application or other users,
        according to their discretion.<br />
        4. Users fully understand and agree that this application and this
        company shall not be liable for any acts of third parties that may
        infringe on your rights and interests, including but not limited to the
        upload of your works to this application and any possible actions
        resulting from this without your knowledge or consent.<br />
        5. Users fully understand and agree that third parties may access
        relevant information on this application and may use the information.
        This application and this company shall not be liable for any acts that
        may infringe on your rights and interests by users or third parties in
        any way.<br />
        6. Users fully understand and agree that in the event of any disputed
        content or disputes related to users during the use of the application,
        this company and this application are resolutely intolerant and promise
        to handle them seriously. This includes but is not limited to deleting
        content posted by users, banning or canceling users' personal accounts,
        and providing their account and personal information to relevant
        authorities. Therefore, by using this application, users unconditionally
        accept the constraints and provisions of this clause.</p
      >
      <h5>**VII. Other Tips**</h5>
      <p
        >1. This company solemnly reminds users to pay attention to the clauses
        in this agreement that exempt this application and this company from
        liability and restrict users' rights. Users should read them carefully,
        independently consider, and distinguish the related risks. Minors should
        read this agreement and use the services of this application under the
        accompaniment of legal guardians.<br />
        2. The validity, interpretation, and resolution of disputes of this
        agreement shall be governed by the laws of the People's Republic of
        China. In the event of any dispute or disagreement between the user and
        this application and this company, the parties shall first attempt to
        resolve it amicably. If negotiation fails, the user agrees to submit the
        dispute or disagreement to the people's court with jurisdiction over the
        domicile of this company. This company has the right to initiate
        necessary criminal and civil legal procedures to safeguard the
        legitimate rights and interests of this company and pursue the legal
        liabilities of illegal users.<br />
        3. If any provision of this agreement is invalid or unenforceable for
        any reason, the remaining provisions shall remain valid and enforceable,
        and shall be binding on both parties.<br />
        4. The failure of this application software and this company to exercise
        or enforce any right or provision of this service agreement shall not
        constitute a waiver of such right or provision.<br />
        5. Any matter not covered in this agreement shall be subject to the
        updates or supplements of the relevant content of this agreement by the
        operation backstage of this application and this company from time to
        time. The updated and supplementary terms shall be inseparable from this
        agreement.<br />
        6. The final interpretation right of the relevant terms of this
        agreement belongs to this company.</p
      >
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "userAgreement",
  methods: {
    backToLogin() {
      this.$router.push("/loginUp");
    },
  },
};
</script>

<style lang="less">
.userArge {
  overflow-x: hidden !important;
  width: 100vw;

  .top {
    width: 100vw;
    height: 100px;
    border-bottom: 2px solid #dedede;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    img {
      position: absolute;
      top: 35px;
      left: 65px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    p {
      font-size: 32px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 67px;
      letter-spacing: 1px;
    }
  }
  .content {
    padding: 41px 65px 101px 65px;
    h5 {
      margin-top: 42px;
      margin-bottom: 28px;
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 40px;
      letter-spacing: 1px;
    }
    p {
      font-size: 26px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 37px;
      letter-spacing: 1px;
      text-align: justify;
    }
  }
}
</style>